.header-container {
    /* background-color: pink; */
    display: flex;
    justify-content: space-between !important;
    /* padding: 10px; */
    align-items: center;
}

.header-logo-btn {
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
}

.header-logo {
    margin-right: 7px;
}

.header-links-container {
    /* background-color: lime; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.header-links {
    margin: 10px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-size: 15px;
    padding: 5px
}

.header-links-icons {
    margin-right: 7px;
}

.social-media-container {
    /* background-color: pink; */
}

.large-icon {
    height: 15px;
    width: 15px;
    margin: 0px 5px;
    cursor: pointer;
}

.small-icon {
    height: 20px;
    width: 20px;
    margin: 0px 5px;
    cursor: pointer;
}
.background-orange {
    background-color: orange;
}

@media screen and (max-width:1255px) {
    .header-container{
        flex-direction: column;
    }
}

@media screen and (max-width:930px) {
    .header-container{
        display: none !important;
    }
}